<template>
  <div>
    <el-container>
      <el-aside width="200px"
        height="100vh"
        min-height="900px">
        <div class="logo">
          <router-link :to="{ name: 'Home' }"
            tag="a"
            class="logo_in">
            {{ app_name }}</router-link>
        </div>
        <el-col>
          <el-menu :background-color="shared_layout.colors.menu_bg_color"
            :text-color="shared_layout.colors.menu_text_color"
            :router="true"
            :default-active="default_active_page"
            class="el-menu-vertical-demo"
            style="height: 80vh; overflow-y: hidden">
            <el-menu-item index="/dashboard">
              <span class="material-icons nav-item-icon"> dashboard </span>
              <span slot="title">{{ $t("buttons.console") }}</span>
            </el-menu-item>
            <el-menu-item index="/dashboard/task-center">
              <span class="material-icons nav-item-icon">assignment</span>
              <span slot="title">{{ $t("buttons.task_center") }}</span>
            </el-menu-item>
            <el-menu-item index="/dashboard/users-management"
              v-if="user_name == admin_name">
              <span class="material-icons nav-item-icon"> group </span>
              <span slot="title">{{ $t("buttons.user_management") }}</span>
            </el-menu-item>
            <el-menu-item index="/dashboard/computation-nodes"
              v-if="user_name == admin_name">
              <span class="material-icons nav-item-icon"> storage </span>
              <span slot="title">{{ $t("buttons.computation_nodes") }}</span>
            </el-menu-item>
            <el-menu-item index="/dashboard/user-center">
              <span class="material-icons nav-item-icon"> person </span>
              <span slot="title">{{ $t("buttons.user_center") }}</span>
            </el-menu-item>
            <el-menu-item index="/dashboard/api-doc">
              <span class="material-icons nav-item-icon"> code </span>
              <span slot="title">{{ $t("buttons.api_doc") }}</span>
            </el-menu-item>
            <el-menu-item index="/dashboard/user-manual">
              <span class="material-icons nav-item-icon"> book </span>
              <span slot="title">{{ $t("buttons.user_manual") }}</span>
            </el-menu-item>
            <el-menu-item index="/dashboard/map-view">
              <span class="material-icons nav-item-icon"> map </span>
              <span slot="title">{{ $t("buttons.map_view") }}</span>
            </el-menu-item>
            <el-menu-item index="/dashboard/downloads">
              <span class="material-icons nav-item-icon"> cloud_download </span>
              <span slot="title">{{ $t("buttons.download_app") }}</span>
            </el-menu-item>
          </el-menu>
        </el-col>
        <div class="lang-switch">
          <div v-if="lang_status == 'zh_cn'">
            <span style="color: white">中文</span>
            <span style="color: white"> / </span>
            <span style="color: gray">EN</span>
          </div>
          <div v-else>
            <span style="color: gray">中文</span>
            <span style="color: white"> / </span>
            <span style="color: white">EN</span>
          </div>
          <el-switch :value="lang_status"
            active-color="#13ce66"
            inactive-color="#13ce66"
            active-value="en"
            inactive-value="zh_cn"
            @change="langChange">
          </el-switch>
        </div>
      </el-aside>
      <el-main>
        <router-view />
      </el-main>
    </el-container>
  </div>
</template>

<script>
import apiURL from "@/data/api";
import { mapState, mapGetters, mapActions } from "vuex";
export default {
  name: "Dashboard",
  components: {},
  data() {
    return {
      // smart_farm_icon: require("@/assets/field.svg"),
      ws_notification_client: null,
      ws_heartbeat_client: null,

      ws_node_status_client: null,
    };
  },
  async mounted() {
    await this.initReceiveHeartbeat();
    await this.initReceiveNotification();
    await this.initReceiveNodeStatus();
    await this.askNotificationPermission();
  },
  destroyed() {
    this.ws_notification_client = null;
    this.ws_heartbeat_client = null;
    this.destroyReceiveNodeStatus();
  },
  methods: {
    ...mapActions(["updateNodeInfo", "getNodesStatistics", "getLangChange"]),
    langChange(e) {
      let vm = this;
      vm.$i18n.locale = e;
      vm.getLangChange(e);
    },
    async askNotificationPermission() {
      console.log('try to get push notification3')
      // 检查浏览器是否支持通知
      if (!("Notification" in window)) {
        console.log("此浏览器不支持通知。");
        return;
      }
      Notification.requestPermission().then((permission) => {
        // 根据用户的回答显示或隐藏按钮
        // notificationBtn.style.display = permission === "granted" ? "none" : "block";
        console.log("此浏览器通知状态2", permission);
        if (permission == "granted"){
          console.log("good");
          new Notification("待办列表", { body: "hello" });

        }
        else{
          console.log("wtf");
        }
        console.log("此浏览器通知状xxxxx态");
      });
    },
    async destroyReceiveNodeStatus() {
      this.ws_node_status_client = null;
    },
    async initReceiveNodeStatus() {
      
      let vm = this;
      console.log("initReceiveNodeStatus");
      this.ws_node_status_client = new WebSocket(
        process.env.VUE_APP_WS_API_BASE_URL +
        apiURL.ws_stage +
        apiURL.ws.nodes_status.nodes
      );
      this.ws_node_status_client.onopen = () => {
        const auth_info = {
          user_id: vm.user_statistics.id,
          access_token: vm.access_token,
        };
        if (vm.ws_node_status_client !== null) {
          vm.ws_node_status_client.send(JSON.stringify(auth_info));
          vm.ws_node_status_client.send(this.session_id);
        }
      };

      this.ws_node_status_client.onmessage = (event) => {
        if (vm.ws_node_status_client !== null) {
          vm.ws_node_status_client.send("pong");
        }
        // 更新到vuex里
        vm.updateNodeInfo(event.data);
      };

      this.ws_node_status_client.onclose = () => {
        console.log(
          "Nodes status connection closed. Reconnect will be attempted in " +
          vm.reconnect_interval / 1000 +
          " second."
        );
        if (
          performance.navigation.type !== performance.navigation.TYPE_RELOAD
        ) {
          // not reload
          setTimeout(() => {
            vm.initReceiveNodeStatus();
          }, vm.reconnect_interval);
        }
      };
      return true;
    },
    async initReceiveNotification() {
      let vm = this;
      console.log("initReceiveNotification");

      this.ws_notification_client = new WebSocket(
        process.env.VUE_APP_WS_API_BASE_URL + apiURL.ws_stage + apiURL.ws.test
      );
      this.ws_notification_client.onopen = () => {
        const auth_info = {
          user_id: vm.user_statistics.id,
          access_token: vm.access_token,
        };
        if (vm.ws_notification_client !== null) {
          vm.ws_notification_client.send(JSON.stringify(auth_info));
          vm.ws_notification_client.send(this.session_id);
        }
      };

      this.ws_notification_client.onmessage = (event) => {
        let content = JSON.parse(event.data);

        const h = vm.$createElement;

        vm.$notify({
          // title: "Sent at " + content.time,
          message: h("i", { style: "color: teal" }, content.msg),
          type: content.type,
          duration: 3000,
          customClass: "system-wide-notification",
        });
      };

      this.ws_notification_client.onclose = () => {
        if (
          performance.navigation.type !== performance.navigation.TYPE_RELOAD
        ) {
          console.log(
            "Notification connection closed. Reconnect will be attempted in " +
            vm.reconnect_interval / 1000 +
            " second."
          );
          setTimeout(() => {
            vm.initReceiveNotification();
          }, vm.reconnect_interval);
        }
      };
    },

    async initReceiveHeartbeat() {
      console.log("initReceiveHeartbeat");
      let vm = this;
      this.ws_heartbeat_client = new WebSocket(
        process.env.VUE_APP_WS_API_BASE_URL +
        apiURL.ws_stage +
        apiURL.ws.heartbeat
      );
      this.ws_heartbeat_client.onopen = () => {
        const auth_info = {
          user_id: vm.user_statistics.id,
          access_token: vm.access_token,
        };
        if (vm.ws_heartbeat_client !== null) {
          vm.ws_heartbeat_client.send(JSON.stringify(auth_info));
          vm.ws_heartbeat_client.send(this.session_id);
        }
      };

      this.ws_heartbeat_client.onmessage = (event) => {
        if (vm.ws_heartbeat_client !== null) {
          vm.ws_heartbeat_client.send("pong");
        }
        return event;
      };

      this.ws_heartbeat_client.onclose = () => {
        if (
          performance.navigation.type !== performance.navigation.TYPE_RELOAD
        ) {
          console.log(
            "Heartbeat connection closed. Reconnect will be attempted in " +
            vm.reconnect_interval / 1000 +
            " second."
          );
          setTimeout(() => {
            vm.initReceiveHeartbeat();
          }, vm.reconnect_interval);
        }
      };
    },
  },

  computed: {
    ...mapGetters(["headers4reqs", "access_token"]),
    ...mapState({
      user_statistics: (state) => state.user.statistics,
      user_name: (state) => state.user.statistics.name,
      lang_status: (state) => state.lang_status,
      session_id: (state) => state.session_id,
      admin_name: (state) => state.admin_name,
      users_list: (state) => state.users,
      app_name: (state) => state.app_name,
      default_active_page: (state) => state.default_active_page,
      nodes_status: (state) => state.nodes_status,
    }),
  },
};
</script>
<style lang="scss">
.system-wide-notification {
  font-family: Avenir, Helvetica, Arial, sans-serif !important;
}
</style>

<style lang="scss" scoped>
@import "@/style/main";

.el-aside {
  background: $menu-bg-color;
  color: $menu-text-color;
  // border-right: 1px solid red;

  .logo {
    padding: 20px;
    text-align: left;

    cursor: pointer;

    .logo_in {
      color: #fff;
      text-decoration: none;
      font-weight: bold;
      font-size: 1.2rem;
      display: flex;
      align-items: flex-end;
    }
  }

  .el-menu-vertical-demo {
    // height: 100%;
    border-right: none;
    text-align: left;

    .el-menu-item {
      text-align: left;

      .nav-item-icon {
        width: 24px;
        margin-right: 0.5rem;
      }
    }
  }
}
</style>
